<template>
  <v-card flat tile class="mb-3">
    <v-card-title class="flex-column grey lighten-4">
      <p>{{ value.name || "[Section]" }}</p>
      <p class="caption">{{ value.description }}</p>
    </v-card-title>
    <v-card-text class="pa-0">
      <survey-question
        v-for="(surveyQuestion, index) in value.questions"
        v-model="value.questions[index]"
        :key="surveyQuestion.rank"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "SurveySection",
    props: {
      value: Object
    },
    components: {
      SurveyQuestion: () => import("./SurveyQuestion")
    }
  };
</script>

<style></style>
